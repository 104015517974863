<template>
  <div>
    <a-drawer :title="`${form.id ? '编辑' : '新建'}标签`" :width="720" :visible="visible"
      :body-style="{ paddingBottom: '80px' }" :footer-style="{ textAlign: 'right' }" @close="onClose">
      <a-form :model="form" :rules="rules" layout="vertical">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="标签名称" name="name">
              <a-input v-model:value="form.name" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="标签分类" name="category_id">
              <a-select v-model:value="form.category_id" :options="categoryList" placeholder="请选择"></a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="标签类型" name="type">
              <a-select v-model:value="form.type" @change="handleChangeType" :options="[{
                label: '选择型',
                value: 1
              }, {
                label: '输入型',
                value: 2
              }, {
                label: '计算型',
                value: 3
              }]" placeholder="请选择"></a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12" v-if="form.type == '2' || form.type == '3'">
            <a-form-item label="单位" name="unit">
              <a-input v-model:value="form.unit" placeholder="请输入" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" v-if="form.type == '3'">
          <a-col :span="8">
            <a-select :value="responseList[0]?.id || undefined" @change="handleChange3One" :options="jsList"
              placeholder="请选择"></a-select>
          </a-col>
          <a-col :span="8">
            <a-button type="primary" @click="addResponse">添加</a-button>
          </a-col>
          <template v-for="(item, idx) in responseList" :key="idx">
            <template v-if="idx != 0">
              <a-col :span="24">
                <a-row :gutter="16" style="margin-top:12px">
                  <a-col :span="8">
                    <a-select v-model:value="item.sign" :options="[{
                      label: '加',
                      value: '+'
                    }, {
                      label: '减',
                      value: '-'
                    }, {
                      label: '乘',
                      value: '*'
                    }, {
                      label: '除',
                      value: '/'
                    },]" placeholder="请选择"></a-select>
                  </a-col>
                  <a-col :span="8">
                    <a-select v-model:value="item.id" :options="jsList" placeholder="请选择"></a-select>
                  </a-col>
                  <a-col :span="8">
                    <a-button type="danger" @click="delResponse(idx)">删除</a-button>
                  </a-col>
                </a-row>
              </a-col>
            </template>
          </template>
        </a-row>
      </a-form>
      <template #extra>
        <a-space>
          <a-button @click="onClose">取消</a-button>
          <a-button :loading="createLoading" type="primary" @click="onConfirm">提交</a-button>
        </a-space>
      </template>
    </a-drawer>
  </div>
</template>
<script>
// import UploadFile from '@/components/common/uploadFile.vue';
import service from '@/service/service';
import { message } from 'ant-design-vue';
const rules = {
  name: [{
    required: true,
    message: '请输入标签名称',
  }],
  category_id: [{
    required: true,
    message: '请选择分类',
  }],
  type: [{
    required: true,
    message: '请选择类型',
  }],
};
export default {
  components: {
    // UploadFile
  },
  props: ['current'],
  data() {
    return {
      rules,
      form: {
        id: '',
        category_id: '',
        type: '',
        unit: '',
        name: '',
      },
      responseList: [],
      jsList: [],
      categoryList: [],
      visible: false,
      createLoading: false,
    }
  },
  watch: {
    current: {
      async handler(nowCurrent) {
        if (nowCurrent) {
          let temp = {};
          for (let key in this.form) {
            temp[key] = nowCurrent[key];
          }
          this.form = temp;
          this.responseList = [];
        }
      },
      immediate: true
    }
  },
  created() {
    this.getOptions();
  },
  methods: {
    handleChange3One(e) {
      if (this.responseList?.length) {
        this.responseList[0].id = e;
      }
      else {
        this.responseList = [{
          id: e,
          sign: undefined,
        }];
      }
    },
    delResponse(e) {
      this.responseList = this.responseList.filter((f, idx) => idx != e);
    },
    handleChangeType(e) {
      // console.log(e);
      if (e == 3) {
        this.responseList = this.responseList.length ? this.responseList : [{
          id: undefined,
          sign: undefined,
        }]
      }
    },
    addResponse() {
      this.responseList = this.responseList.concat([{
        id: undefined,
        sign: undefined,
      }])
    },
    async getOptions() {
      const { data: depts } = await service.get_type_lists({ limit: 1000 });
      this.categoryList = depts.map((m) => ({ label: m.name, value: m.id }));

      const { data: tags } = await service.get_input_tag();
      this.jsList = tags.map(m => ({
        label: m.name,
        value: m.id + ''
      }));

    },
    onClose() {
      this.visible = false;
      this.onReset();
      this.$emit('close');
    },
    onReset() {
      let temp = {};
      for (let key in this.form) {
        temp[key] = '';
      }
      this.form = temp;
    },
    async onShow(id) {
      this.visible = true;

      if (id) {
        this.createLoading = true;
        let res = await service.get_tag_info({ id });
        this.responseList = res.data.formula?.map?.(m => ({ ...m, id: m.id || undefined })) || [];
        this.createLoading = false;
      }
    },
    async onConfirm() {
      this.createLoading = true;
      const api = this.form.id ? 'edit_tag' : 'add_tag';
      // console.log(this.responseList);
      let formula = this.responseList.map(m => ({
        id: m.id || '',
        sign: m.sign || ''
      }));
      const { code, msg } = await service[api]({
        ...this.form,
        formula
      })
      this.createLoading = false;
      if (code && code == '1') {
        message.success(msg || '成功');
        this.$emit('refreshList');
        this.onClose();
      }
    }
  }
}
</script>